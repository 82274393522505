.pacient_steps--dialog {
    &__table {
        padding: 26px 8px;
        @media (min-width: 768px) {
            padding: 26px 42px;
        }
        &--title {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            color: #2b2e69;
            text-align: center;
            padding: 0 30px;
        }
        &--table {
            table {
                border-collapse: collapse;
                width: 100%;
                margin-top: 20px;
                font-size: 13px;
                @media (min-width: 768px) {
                    font-size: 18px;
                }
                tr {
                    th {
                        padding: 5px;
                        height: 52px;
                        background: #7dbfbf;
                        color: #fff;
                        font-weight: 700;
                        line-height: 120%;
                        border-right: 1px solid #fff;
                        border-bottom: 2px solid #fff;
                        width: 30%;
                        &:first-child {
                            border-radius: 10px 0px 0px 0px;
                            text-align: left;
                            padding-left: 8px;
                            @media (min-width: 768px) {
                                padding-left: 30px;
                            }
                        }
                        &:last-child {
                            border-radius: 0px 10px 0px 0px;
                            border-right: none;
                        }
                    }
                    td {
                        font-weight: 400;
                        line-height: 120%;
                        color: #2b2e69;
                        text-align: center;
                        height: 55px;
                        padding: 5px 8px;
                        &:first-child {
                            text-align: left;
                        }
                        @media (min-width: 768px) {
                            padding: 5px 30px;
                        }
                }
                    &:nth-child(odd) {
                        td {
                            background-color: rgba(160, 163, 219, 0.2);
                        }
                    }
                }
            }
            &.table-small-height {
                table {
                    tr {
                        th {
                            height: 36px;
                        }
                        td {
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
