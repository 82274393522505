.specialist_main {
    display: flex;
    align-items: center;
    h2 {
        font-weight: 700;
        color: #ffffff;
        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
        margin: 0;
        font-size: 30px;
        line-height: 120%;
        text-align: center;
        margin: 20px 0;
        @media (min-width: 768px) {
            font-size: 42px;
            text-align: left;
            margin: 0px 0;
        }
    }
    &__descriptions {
        margin-top: 44px;
        width: 100%;
        overflow: auto;
        // direction: rtl;
        order: 1;
        @media (min-width: 768px) {
            height: 500px;
        }
        &::-webkit-scrollbar {
            background: #43479c;
            width: 10px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: #3b989b;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            height: auto;
        }
        @media (min-width: 768px) {
            order: 0;
        }
        > div {
            // direction:ltr;
            @media (min-width: 992px) {
                display: flex;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                color: #ffffff;
                margin: 0 0 18px 0;
            }
        }
        &.specialists__ped {
            > div {
                > div {
                    &:nth-child(1) {
                        margin-right: 20px;
                        @media (min-width: 992px) {
                            margin-right: 60px;
                            width: 60%;
                        }
                    }
                    &:nth-child(2) {
                        @media (min-width: 992px) {
                            width: 40%;
                        }
                    }
                }
            }
        }
        &.specialists__nevr {
            > div {
                > div {
                    margin-right: 0px;
                    text-align: center;
                    @media (min-width: 768px) {
                        text-align: left;
                    }
                    &:nth-child(1) {
                        @media (min-width: 992px) {
                            margin-right: 60px;
                            width: 60%;
                        }
                    }
                    &:nth-child(2) {
                        @media (min-width: 992px) {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        > div {
            @media (min-width: 768px) {
                width: 60%;
            }
        }
    }
    &__buttons {
        padding: 0;
        list-style: none;
        @media (min-width: 768px) {
            padding-left: 30px;
        }
        li {
            cursor: pointer;
            margin: 0px auto 0;
            img {
                display: block;
                position: relative;
                bottom: -5px;
            }
            &.specialists {
                &__ped {
                    max-width: 347px;
                    img {
                        width: 100%;
                        bottom: -5px;
                    }
                }
                &__nevr {
                    max-width: 340px;
                    img {
                        width: 100%;
                        bottom: -30px;
                    }
                }
            }
            > div {
                max-width: 304px;
                height: 68px;
                background: #ffffff;
                border-radius: 50px;
                margin: 0 auto 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-image: url("../../../assets/images/specialists/button-arr.svg");
                background-repeat: no-repeat;
                background-position: calc(100% - 27px) center;
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 120%;
                    color: #40428c;
                }
            }
            &.specialists__ped {
                @media (min-width: 992px) {
                    margin-top: -60px;
                }
            }
            &.specialists__nevr {
                @media (min-width: 992px) {
                    margin: -100px auto 0;
                }
            }
        }
    }
}
