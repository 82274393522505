.pacient_steps--content-info {
    padding: 15px 10px;
    height: auto;
    background: #ffffff;
    border-radius: 24px;
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        padding: 25px 20px;
        // flex: 1;
        width: 60%;
    }
    &__pacient {
        width: 120px;
        margin: 0 auto 0;
        @media (min-width: 1024px) {
            width: 160px;
        }
        @media (max-width: 768px) {
            width: 160px;
        }
        &-photo {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 6px solid #7dbfbf;
            @media (min-width: 1200px) {
                width: 160px;
                height: 160px;
            }
            @media (max-width: 768px) {
                width: 160px;
                height: 160px;
            }
            img {
                display: block;
                width: 100%;
            }
            @media (min-width: 768px) {
            }
        }
        &-name {
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #40428c;
            padding: 10px 0;
            @media (min-width: 1200px) {
                font-size: 24px;
            }
            @media (max-width: 768px) {
                font-size: 24px;
            }
        }
    }
    .table-wrap {
        border-radius: 10px 10px 0px 0px;
        border: 1px solid #cfcfcf;
        height: 364px;
        // overflow: hidden;
        overflow: auto;
        position: relative;
        @media (min-width: 768px) {
            margin-left: 28px;
        }
        &::-webkit-scrollbar {
            box-shadow: inset 0.375rem 0 0.75rem 0 #d9d9d9;
            width: 10px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: #3b989b;
            border-radius: 5px;
        }
    }
    table {
        color: #2b2e69;
        line-height: 120%;
        border-collapse: collapse;
        // width: 100%;
        font-size: 13px;
        min-height: 100%;
        @media (min-width: 1200px) {
            font-size: 18px;
        }
        @media (min-width: 768px) {
            font-size: 16px;
        }
        thead {
            top: 0;
            position: sticky;
            tr {
                th {
                }
                &:nth-child(1) {
                    th {
                    }
                }
                &:nth-child(2) {
                    th {
                    }
                }
            }
        }
        tr {
            th {
                font-weight: 400;
                border-bottom: 1px solid #cfcfcf;
                border-right: 1px solid #cfcfcf;
                height: 56px;
                padding: 13px;
                background-color: #fff;
                &:last-child {
                    border-right: none;
                }
            }
            &:first-child {
                th {
                    background-color: #7dbfbf;
                    height: 48px;
                    font-weight: 700;
                    color: #ffffff;
                }
            }
            td {
                border-right: 1px solid #cfcfcf;
                padding: 13px;
                &:nth-child(2) {
                    @media (max-width: 1200px) {
                        font-size: 14px;
                    }
                }
                &:last-child {
                    border-right: none;
                }
            }
            &:last-child {
                td {
                    // border-bottom: 1px solid #cfcfcf;
                }
            }
            &:nth-child(odd) {
                td {
                    background-color: rgba(217, 217, 217, 0.15);
                }
            }
        }
    }
    .accordion {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        @media (min-width: 768px) {
            margin-left: 30px;
            flex: 1;
            max-height: 440px;
        }
        &__item {
            display: flex;
            flex-direction: column;
            max-height: 100%;
            margin: 0px 0 12px 0;
            &:last-child {
                margin-bottom: 0px;
            }
        }
        &__heading {
            position: relative;
        }
        &__button {
            background: linear-gradient(90deg, #7dbfbf 0%, #77c9ca 70.49%);
            border-radius: 10px;
            height: 40px;
            padding: 9px 40px 9px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            color: #ffffff;
            position: relative;
            @media (min-width: 768px) {
                font-size: 16px;
            }
            @media (min-width: 1200px) {
                font-size: 18px;
            }
            i {
                background-image: url("../../../../../../assets/images/pacients/ico-arr-accordion.svg");
                background-repeat: no-repeat;
                background-position: 8px 5px;
                background-color: #236869;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                position: absolute;
                right: 10px;
                transition: transform 0.4s ease 0s;
            }
            &[aria-expanded="true"] {
                i {
                    transform: rotate(90deg);
                }
            }
        }
        &__panel {
            background: #ffffff;
            border-width: 0px 2px 2px 2px;
            border-style: solid;
            border-color: #cfcfcf;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
            border-radius: 0px 0px 10px 10px;
            padding: 16px 10px 10px 12px;
            overflow: hidden;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #2b2e69;
            margin-top: -6px;
            @media (min-width: 768px) {
                font-size: 14px;
                padding: 16px 10px 10px 12px;
            }
            @media (min-width: 1200px) {
                font-size: 16px;
                padding: 16px 10px 10px 25px;
            }
            @media (max-width: 768px) {
                max-height: none !important;
            }
            > div {
                overflow: auto;
                max-height: 100%;
                padding-right: 5px;
                &::-webkit-scrollbar {
                    box-shadow: inset 0.375rem 0 0.75rem 0 #d9d9d9;
                    width: 10px;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #3b989b;
                    border-radius: 5px;
                }
            }
            p {
                margin: 0;
            }
            ul {
                li {
                    padding-left: 15px;
                    position: relative;
                    &::before {
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #2b2e69;
                        position: absolute;
                        left: 0px;
                        top: 7px;
                    }
                }
            }
        }
    }
}
