.pacient_steps--dialog {
    &__finish {
        padding: 16px;
        @media (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 50px;
        }
        @media (max-height: 600px) {
            justify-content: center;
            padding: 20px;
        }
        @media (min-height: 600px) and (min-width: 768px) {
            min-height: 600px;
        }
        &--specialist_img {
            border: 6px solid #3fb5b6;
            width: 270px;
            height: 270px;
            border-radius: 50%;
            position: relative;
            margin: 20px auto 0;
            @media (max-height: 600px) and (min-width: 768px)  {
                margin: 0 30px 0 0;
            }
            @media (min-height: 600px) and (min-width: 768px) {
                margin: 0 50px 0 0;
                flex: 0 0 auto;
                width: 294px;
                height: 294px;
            }
            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 270px;
                @media (min-height: 600px) and (min-width: 768px) {
                    width: 306px;
                }
            }
            &_tool {
                position: absolute;
                width: 200px;
                height: 100px;
                right: -15px;
                bottom: 20px;
                background-color: #236869;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Montserrat";
                font-weight: 700;
                color: #ffffff;
                @media (min-height: 600px) and (min-width: 768px) {
                    width: 226px;
                    height: 127px;
                    left: 70%;
                    right: auto;
                    bottom: 100%;
                }
                &::after {
                    content: "";
                    border-bottom: 55px solid #236869;
                    border-right: 44px solid transparent;
                    position: absolute;
                    top: -30px;
                    left: 85px;
                    @media (min-height: 600px) and (min-width: 768px) {
                        top: 100%;
                        left: 35px;
                        border-top: 55px solid #236869;
                        border-right: 44px solid transparent;
                        border-bottom: none;
                    }
                }
                b {
                    font-size: 80px;
                    line-height: 120%;   
                    @media (min-height: 600px) and (min-width: 768px) {
                        font-size: 120px;
                    } 
                }
                p {
                    font-size: 22px;
                    line-height: 27px;
                }
                &.warning {
                    background-color: #EF5DA8;
                    &::after {
                        border-bottom-color: #EF5DA8;
                        @media (min-height: 600px) and (min-width: 768px) {
                            border-top-color: #EF5DA8;
                        }
                    }
                }
            }
        }
        &--buttons {
            &_title {
                font-weight: 700;
                font-size: 24px;
                line-height: 120%;
                margin: 25px 0;
                color: #2b2e69;
                text-align: center;
                @media (min-height: 600px) and (min-width: 768px) {
                    font-size: 42px;
                    text-align: left;
                }
            }
            ul {
                li {
                    padding: 12px 16px 12px 16px;
                    min-height: 46px;
                    background: linear-gradient(90deg, #8b8cbd 0.38%, #a0a3db 97.2%);
                    border-radius: 10px;
                    margin: 12px 0;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 21px;
                    color: #ffffff;
                    cursor: pointer;
                    position: relative;
                    @media (min-width: 768px) {
                        max-width: 390px;
                    }
                    &.active {
                        background: #44acae;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}