.pacient_steps--dialog {
    &__text {
        padding: 40px 16px 20px 16px;
        min-height: auto;
        @media (min-width: 768px) {
            padding: 49px 76px 22px 66px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        @media (min-height: 600px) and (min-width: 768px) {
            min-height: 600px;
        }
        &--title {
            background: #44acae;
            border-radius: 40px 10px 10px 40px;
            height: auto;
            display: flex;
            min-height: 56px;
            align-items: center;
            margin-bottom: 32px;
            position: relative;
            margin-left: 30px;
            @media (min-width: 768px) {
                margin-left: 50px;
                height: 56px;
            }
            b {
                font-size: 16px;
                line-height: 120%;
                color: #ffffff;
                padding-left: 50px;
                padding-right: 5px;
                @media (min-width: 768px) {
                    font-size: 24px;
                    padding-left: 74px;
                    padding-right: 24px;
                }
            }
            .dopQuestions &,
            .dopQuestions-2 & {
                min-height: 56px;
                height: auto;
                padding: 10px;
                border-radius: 10px;
                text-align: center;
                justify-content: center;
                margin-left: 30px;
                b {
                    padding-left: 30px;
                }
                @media (min-width: 768px) {
                    margin-left: 50px;
                    b {
                        padding-left: 50px;
                    }
                }
            }
            i {
                border: 3px solid #3fb5b6;
                width: 68px;
                height: 68px;
                border-radius: 50%;
                background-color: #fff;
                flex: 0 0 auto;
                position: absolute;
                left: -30px;
                @media (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    border: 5px solid #3fb5b6;
                    left: -50px;
                }
                img {
                    width: 100%;
                }
            }
            .conclusion & {
                text-align: center;
                b {
                    width: 100%;
                    padding: 0 10px 0 50px;
                }
            }
        }
        &--content {
            color: #2b2e69;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .conclusion & {
                > div {
                    &:nth-child(1) {
                        @media (min-width: 768px) {
                            padding-top: 40px;
                        }
                    }
                }
            }
            p {
                margin: 0 0 18px;
                font-size: 14px;
                line-height: 120%;
                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }
            ul {
                margin-top: -10px;
                li {
                    font-size: 14px;
                    line-height: 120%;
                    text-align: center;
                    @media (min-width: 768px) {
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        margin: 7px 0;
                    }
                    > i {
                        border: 3px solid #3fb5b6;
                        border-radius: 50%;
                        overflow: hidden;
                        width: 60px;
                        height: 60px;
                        display: inline-block;
                        margin: 15px auto 10px;
                        @media (min-width: 768px) {
                            margin: 0 9px 0 0;
                            flex: 0 0 auto;
                        }
                        img {
                            width: 100%;
                        }
                        &.hidden-image {
                            border: 3px solid transparent;
                            height: auto;
                            display: none;
                            @media (min-width: 768px) {
                                display: block;
                            }
                        }
                    }
                    span {
                        padding-left: 0px;
                        display: block;
                        text-align: left;
                        @media (min-width: 768px) {
                            padding-left: 9px;
                        }
                    }
                }
            }
            .dopQuestions &,
            .conclusion & {
                @media (min-width: 768px) {
                    padding: 0 40px;
                }
                p {
                    font-size: 16px;
                    @media (min-width: 768px) {
                        font-size: 24px;
                    }
                }
            }
            .dopQuestions-2 & {
                p {
                    font-size: 14px;
                    margin: 8px 0;
                    @media (min-width: 768px) {
                        font-size: 18px;
                    }
                }
            }
            .padding-text & {
                p {
                    @media (min-width: 768px) {
                        padding: 0 40px;
                    }
                }
                ul {
                    @media (min-width: 768px) {
                        padding: 0 20px;
                    }
                    li {
                        @media (min-width: 768px) {
                            margin: 20px 0;
                        }
                    }
                }
            }
            &-button {
                padding: 12px 16px;
                max-width: 440px;
                // height: 49px;
                background: #a0a3db;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 20px auto 35px;
                cursor: pointer;
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 110%;
                    color: #ffffff;
                    text-align: center;
                    flex: 1;
                    @media (min-width: 768px) {
                        font-size: 21px;
                    }
                }
                i {
                    background-image: url("../../../../../../../assets/images/pacients/ico-arr-accordion.svg");
                    background-repeat: no-repeat;
                    background-position: 8px 5px;
                    background-color: #40428c;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                }
            }
            &-diagnosis {
                margin-top: 20px;
                min-height: 46px;
                padding: 5px;
                border: 3px solid #7dbfbf;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                color: #236869;
                @media (min-width: 768px) {
                    font-size: 18px;
                    margin-top: 5px;
                }
                .conclusion & {
                    min-height: 79px;
                }
            }
            &-note {
                font-size: 12px;
                line-height: 14px;
                text-align: right;
                color: #000000;
                margin-top: 15px;
            }
        }
    }
}
