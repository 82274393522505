.pacient_steps {
    padding-top: 10px;
    @media (min-width: 768px) {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
    }
    &--container {
        &-notes {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #ffffff;
            width: 100%;
            padding: 10px 0 36px;
        }
        @media (min-width: 768px) {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
        }
    }
    &--content {
        padding-top: 20px;
        width: 100%;
        @media (min-width: 768px) {
            padding-top: 60px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}
