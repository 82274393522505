.pacient_select {
    display: flex;
    align-items: center;
    > div {
        // width: 100%;
        flex: 1;
    }
    .step_game-header-back {
        margin: 23px 0 0px 0;
        position: relative;
        z-index: 1;
        @media (min-width: 768px) {
            margin: 0 0 20px 0;
        }
    }
    h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 120%;
        color: #ffffff;
        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
        margin: 20px;
        text-align: center;
        @media (min-width: 768px) {
            font-size: 42px;
            text-align: left;
            margin: 0;
        }
    }
    .reset-game {
        width: 304.31px;
        height: 70.06px;
        background: #ffffff;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 40px auto 20px;
        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            display: flex;
            color: #2B2E69;
        }
        @media (min-width: 768px) {
            margin: 20px auto -63px;
        }
    }
    &__main {
        background-size: 100% 163px;
        background-repeat: no-repeat;
        background-position: bottom;
        padding-bottom: 28px;
        display: flex;
        align-items: center;
        @media (min-width: 768px) {
            // margin-top: 85px;
            height: 495px;
            background-image: linear-gradient(to bottom, #a0a3db 0%, #a0a3db 100%);
        }
        @media (min-width: 1200px) {
            align-items: flex-end;
        }
    }
    &__body {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: -30px;
    }
    &__specialist {
        // width: 320px;
        // height: 320px;
        background: #a0cfcf;
        border: 5px solid #ffffff;
        border-radius: 50%;
        display: none;
        align-items: flex-end;
        position: relative;
        z-index: 1;
        @media (min-width: 1200px) {
            display: flex;
        }
        > div {
            border-radius: 50%;
            img {
                display: block;
            }
        }
        &.specialists__ped {
            width: 338px;
            height: 338px;
            > div {
                img {
                    width: 100%;
                }
            }
        }
        &.specialists__nevr {
            width: 338px;
            height: 338px;
            > div {
                img {
                    // width: 100%;
                }
            }
        }
        &--tooltip {
            background: #236869;
            border-radius: 25px;
            position: absolute;
            left: 72%;
            padding: 25px 20px 25px 25px;
            font-size: 16px;
            line-height: 19px;
            span {
                color: #f4f5ff;
                white-space: nowrap;
            }
            &::after {
                content: "";
                border-top: 55px solid #236869;
                border-right: 44px solid transparent;
                position: absolute;
                top: 100%;
                left: 25px;
            }
        }
    }
    &__pacients {
        margin: 0;
        width: 100%;
        @media (min-width: 768px) {
            margin: 86px auto 0;
        }
        ul {
            @media (min-width: 768px) {
                display: flex;
                justify-content: center;
            }
            li {
            }
        }
        &.column-2 {
            @media (min-width: 1200px) {
                width: calc(100% / 1.5);
            }
            .pacient_select__pacient {
                // width: calc(100% / 2 - 10px * 2);
            }
        }
        &.column-3 {
            @media (min-width: 1200px) {
                width: calc(100% / 1.5);
            }
            .pacient_select__pacient {
                @media (min-width: 768px) {
                    width: calc(100% / 3 - 10px * 3);
                }
            }
        }
    }
    &__pacient {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        cursor: pointer;
        margin: 30px 0px;
        @media (min-width: 768px) {
            margin: 0px 10px;
        }
        &-result {
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #7dbfbf;
            padding-bottom: 30px;
            text-align: center;
            white-space: nowrap;
            @media (min-width: 768px) {
                position: absolute;
                bottom: 100%;
            }
            b {
                color: #ffffff;
            }
        }
        &-image {
            background: #ffffff;
            border: 6px solid #7dbfbf;
            border-radius: 50%;
            img {
                display: block;
                width: 100%;
            }
        }
        &-descr {
            padding: 15px 12px 15px 12px;

            width: 100%;
            background: #ffffff;
            border-radius: 24px;
            text-align: center;
            height: 100%;

            display: flex;
            align-items: center;
            position: relative;
            &::after {
                content: "";
                width: 38px;
                height: 38px;
                background-color: #236869;
                flex: 0 0 auto;
                border-radius: 50%;
                background-image: url("../../../../assets/images/pacients/button-arr.svg");
                background-repeat: no-repeat;
                background-position: calc(50% + 1px) 50%;
            }
            @media (min-width: 768px) {
                min-height: 108px;
                max-width: 240px;
            }
            span {
                font-size: 14px;
                line-height: 17px;
                color: #40428c;
                width: 100%;
                @media (min-width: 992px) {
                    font-size: 16px;
                    line-height: 19px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &.diagnose {
                &::after {
                    display: none;
                }
            }
        }
        &.specialists__ped {
            .pacient_select__pacient-image {
                // max-width: 246px;
                // margin-bottom: 38px;
                max-width: 190px;
                margin-bottom: 14px;
            }
            .pacient_select__pacient-descr {
            }
        }
        &.specialists__nevr {
            .pacient_select__pacient-image {
                max-width: 190px;
                margin-bottom: 14px;
            }
            .pacient_select__pacient-descr {
                text-align: left;
                padding-right: 53px;
                &::after {
                    position: absolute;
                    right: 12px;
                    bottom: 20px;
                }
                &.diagnose {
                    text-align: center;
                    padding-right: 12px;
                }
            }
        }
    }
}
