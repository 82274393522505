.pacient_steps--dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    width: auto;
    height: auto;
    background: rgba(43, 46, 105, 0.44);
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    article {
        width: 100%;
        max-width: 1062px;
        max-height: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.5);
        border-radius: 24px;
        position: relative;
        display: flex;
        overflow: hidden;
        @media (min-width: 768px) {
            min-height: 300px;
        }
        section {
            overflow: auto;
            width: 100%;
        }
    }
    &--close {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 8px;
        top: 8px;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        cursor: pointer;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-bottom: 2px solid #3c107f;
            width: 11px;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        @media (min-width: 768px) {
            right: 25px;
            top: 22px;
        }
    }
}
