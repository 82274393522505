.pacient_steps--content-ansvers {
    padding: 20px 0 50px 0px;
    @media (min-width: 768px) {
        height: 100%;
        padding: 0px 0 0px 30px;
        width: 40%;
        // max-width: 480px;
        // width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;
        text-align: center;
        margin-bottom: 20px;
        @media (min-width: 768px) {
        }
    }
    &-block {
        margin-bottom: 0px;
        @media (min-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 50px;
        }
    }
    &__doctor {
        margin-bottom: -16px;
        position: relative;
        &.doctor_result {
            margin-bottom: 0px;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto 0;
            max-height: 330px
        }
        @media (min-width: 768px) {
            // width: 73%;
            img {
            }
        }
        &__tooltip {
            position: absolute;
            right: 72%;
            top: 0px;
            background: #1b6868;
            border-radius: 25px;
            padding: 20px 15px 20px 20px;
            font-size: 16px;
            line-height: 120%;
            display: none;
            &::after {
                content: "";
                border-top: 55px solid #236869;
                border-left: 44px solid transparent;
                position: absolute;
                top: 100%;
                right: 25px;
            }
            .doctor_result & {
                padding: 10px 15px 10px 15px;
                right: 65%;
                &::after {
                    border-top: 27px solid #236869;
                    border-left: 22px solid transparent;
                }
            }
            span {
                font-weight: 400;
                color: #f4f5ff;
                white-space: nowrap;
            }
            b {
                font-weight: 700;
                font-size: 22px;
                line-height: 120%;
                text-align: center;
                display: block;
                color: #f4f5ff;
                white-space: nowrap;
            }
            .nevrolog-finish & {
                right: 80%;
                top: 30px;
                padding: 25px 15px 20px 25px;
                b {
                    font-weight: 400;
                    font-size: 16px;
                    color: #F4F5FF;
                }
                &::after {
                    content: "";
                    border-top: 30px solid #236869;
                    border-left: none;
	                border-right: 35px solid transparent;		
                    position: absolute;
                    top: 25px;
                    left: 100%;
                }    
            }
        }
        &.doctor_result {
            img {
                max-height: 200px;
                @media (min-width: 1200px) {
                    max-height: 240px;
                }
            }
        }
    }
    &-next {
        padding: 12px 16px;
        min-width: 121px;
        background: linear-gradient(90deg, #8b8cbd 0.38%, #a0a3db 97.2%);
        border-radius: 10px;
        margin: 0px 0px 69px 0;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 0px 0;
        @media (min-width: 768px) {
            margin: 20px 0 50px 0;
        }
        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            color: #ffffff;
        }
        i {
            background-image: url("../../../../../../assets/images/pacients/ico-arr-accordion.svg");
            background-repeat: no-repeat;
            background-position: 8px 5px;
            background-color: #40428c;
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
        &.disabled {
            opacity: 0.5;
        }
    }
    &__result {
        > div {
            background: #ffffff;
            border-width: 0px 2px 2px 2px;
            border-style: solid;
            border-color: #cfcfcf;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            padding: 10px 24px;
            font-size: 14px;
            line-height: 120%;
            color: #2b2e69;
            @media (min-width: 992px) {
                font-size: 16px;
            }
            @media (min-width: 1200px) {
                font-size: 18px;
            }
        }
        ul {
            margin-top: 20px;
            li {
                i {
                    transform: rotate(0deg) !important;
                }
            }
        }
    }
    ul {
        width: 100%;
        padding-top: 8px;
        @media (min-width: 768px) {
            padding-top: 0px;
        }
        li {
            padding: 12px 40px 12px 16px;
            min-height: 46px;
            background: linear-gradient(90deg, #8b8cbd 0.38%, #a0a3db 97.2%);
            border-radius: 10px;
            margin: 0px 0 16px 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
            cursor: pointer;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                background-color: #40428c;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                position: absolute;
                right: 10px;
                top: 50%;
                margin-top: -11px;
                transform: rotate(0deg);
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &.active {
                background: #44acae;
                color: #ffffff;
                animation: ansverSow 0.3s ease-in-out 0.1s 1 both;
                i {
                    background-color: #ffffff;
                    transform: rotate(0deg);
                    svg {
                        transform: rotate(0deg) translate(-50%, -50%);
                    }
                }
                @keyframes ansverSow {
                    50% {transform: scale(1.05);}
                    100% {transform: scale(1);}
                }                  
            }
        }
    }
}
