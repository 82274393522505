.pacient_map {
    &--step {
        &.active {
            cursor: pointer;
        }
        &__number {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 54px;
            line-height: 66px;
        }
        &__label {
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
        }
        &__name {
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            color: #FFFFFF;
        }
    }
}