#game-hanter {
    .container {
    }
    .footer-block {
        padding: 24px 0;
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #a0a3db;
        }
    }
    .number-appr {
        text-align: right;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #a0a3db;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 12px;
    }
}
