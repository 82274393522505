.step_game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
        height: 50px;
        flex-wrap: nowrap;
    }
    &-back {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
            width: 33px;
            height: 33px;
            background: #ffffff;
            border-radius: 50%;
            background-image: url('../../../../../assets/images/pacients/ico-back.svg');
            background-repeat: no-repeat;
            background-position: center;
            flex: 0 0 auto;
            @media (min-width: 768px) {
                width: 24px;
                height: 24px;
            }
        }
        b {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: #FFFFFF;
            padding-left: 8px;
            display: none;
            @media (min-width: 1200px) {
                font-size: 24px;
                padding-left: 16px;
            }
            @media (min-width: 768px) {
                display: block;
            }
        }
    }
    &-map {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 427px;
        position: relative;
        order: 1;
        width: 100%;
        margin: 10px auto 0;
        @media (min-width: 768px) {
            margin: 0px 18px;
            order: inherit;
            flex: 1;
        }
        &::before {
            content: '';
            border-bottom: 1px solid #A0A3DB;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 50%;
        }
        @media (min-width: 992px) {
            margin: 0 5%;
        }
        div {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            border: 1px solid #A0A3DB;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #43479C;
            z-index: 1;
            @media (min-width: 992px) {
                height: 36px;
                width: 36px;
            }
            b {
                font-family: 'Montserrat';
                font-weight: 900;
                font-size: 18px;
                color: #FFFFFF;
            }
            &.complite {
                height: 33px;
                width: 33px;
                background-color: #3B989B;
                border-color: #44ACAE;
                @media (min-width: 992px) {
                    height: 48px;
                    width: 48px;
                }
                b {
                    font-size: 24px;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            flex: 1;
            z-index: 1;
            li {
                border: 1px solid #A0A3DB;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #43479C;
                @media (min-width: 992px) {
                    width: 18px;
                    height: 18px;
                }
                &.complite {
                    border-color: #44ACAE;
                    background-color: #44ACAE;
                }
            }
        }
    }
    &-result {
        display: flex;
        align-items: center;
        span {
            font-weight: 700;
            font-size: 18px;
            color: #7DBFBF;
            text-align: right;
            @media (min-width: 1200px) {
                font-size: 24px;
            }
        }
        b {
            font-weight: 700;
            font-size: 38px;
            color: #FFFFFF;
            padding: 0 8px;
            @media (min-width: 1200px) {
                font-size: 42px;
                padding: 0 16px;
            }
        }
    }
}
