.pacient_map {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    @media (min-width: 768px) {
        padding-top: 40px;
    }
    .svg__map {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 auto 0;
        @media (min-width: 768px) {
        }
        svg {
            max-width: 94%;
        }
        &-mobile {
            margin: 0 0 40px 0;
            svg {
                max-width: none;
                margin: 320px auto 60px;
            }
        }
    }
}