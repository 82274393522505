.select_specialist {
    text-align: center;
    display: flex;
    align-items: center;
    h1 {
        font-weight: 700;
        font-size: 30px;
        line-height: 115%;
        color: #ffffff;
        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
        margin: 20px 0;
        @media (min-width: 768px) {
            margin: 0;
            font-size: 42px;
        }
    }
    &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #ffffff;
        margin-top: 27px;
        @media (min-width: 768px) {
            font-size: 24px;
        }
    }
    &__buttons {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 860px;
        gap: 20px;
        margin: 20px auto 40px;
        @media (min-width: 768px) {
        }
        li {
            cursor: pointer;
            width: 50%;
            @media (min-width: 768px) {
                width: auto;
            }
            img {
                display: block;
                position: relative;
            }
            &.specialists {
                &__ped {
                    img {
                        width: 100%;
                        @media (min-width: 768px) {
                            width: 271px;
                        }
                    }
                }
                &__nevr {
                    img {
                        width: 100%;
                        @media (min-width: 768px) {
                            width: 271px;
                        }
                    }
                }
            }
            > div {
                background: #FFFFFF;
                border-radius: 50px;
                height: 40px;
                margin: 0 auto 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                span {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 120%;
                    color: #40428C;    
                    @media (min-width: 768px) {
                        font-size: 24px;
                    }
                }
                @media (min-width: 768px) {
                    width: 260px;
                    height: 68px;
                }
            }
        }
    }
}
